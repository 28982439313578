<template>
    <InlineNotification v-if="isGuider && !user?.hasFinishedSetUp" class="mb-5" type="warning" title="Finish setting up your account"
    text="You haven’t finished setting up your guider account. Add the remaining information so you can start getting paid.">
   <!--  <template v-slot:custom-element>
        <b-button @click="setUpStripeAccount" type="is-ghost" class="warning-ghost-btn" icon-right="arrow-right" size="is-small">Finish
            setup</b-button>
    </template> -->
</InlineNotification>
</template>

<script>
import { mapState } from "vuex";
import { UsersStates } from "../store/Storetypes";
import InlineNotification from '../components/elements/InlineNotification.vue'
import {GuidersMixin} from '../mixins'
export default {
    components: {
        InlineNotification
    },
    mixins: [GuidersMixin],
    computed: {
        ...mapState({
      user: state => state.users[UsersStates.USER],
    })
    },
    watch:{
        user(){
            if (this.user.stripeAccountId) this.goToStripeDashboard()
        }
    },
}
</script>